@import "./vendor/bootstrap/functions";
@import "./vendor/bootstrap/variables";
@import "./vendor/bootstrap/mixins/breakpoints";

body,
.p-component, pre {
  font-family: 'Prompt', sans-serif;
}

.outlet {
  @include media-breakpoint-up(lg) {
    margin-top: -105px;
  }
}

.text-brown {
  color: $brown;
}

.p-fluid .p-fileupload .p-button.p-button-icon-only {
  width: 2.375rem;
}

.p-fileupload-content {
  padding: 0 !important;
  border: none !important;

  .p-progressbar {
    display: none;
  }
}

.p-fileupload .p-fileupload-row>div {
  padding: 0.5rem 0rem !important;
  border-bottom: 1px solid $gray-300;
}

.p-fileupload-buttonbar {
  padding: 1rem 0rem !important;
  background: #fff !important;
  border: none !important;
}

.p-orderlist {
  flex-direction: row-reverse;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  background-color: $gray-200;
  color: $primary;
}

.p-galleria .p-galleria-close {
  z-index: 9999;
}