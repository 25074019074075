@import "../../vendor/bootstrap/functions";
@import "../../vendor/bootstrap/variables";
@import "../../vendor/bootstrap/mixins/breakpoints";

.footer {
    $color: grey;

    .logo {
        min-width: 80px;
        max-width: 200px;
        width: 40%;
    }

    .list-item {
        background-color: inherit;
        padding: 0.3rem 0.2rem;
        border-bottom: 1px dashed $color;
    }

    .list-title {
        a {
            color: $warning;
            font-weight: 700;
        }
    }

    a {
        color: $white;
        text-decoration: none;
        font-weight: 200;
    }

    .header {
        height: 2.3rem;
        font-size: 1.2rem;
        font-weight: 600;
        color: $warning;
    }

    .header-line {
        height: 10px;

        .header-line-1 {
            height: 2px;
            width: 40%;
            max-width: 100px;
            background-color: $yellow;
        }
    }

    .header-temp {
        .header {
            height: 0;
        }

        .header-line {
            height: 0;
        }

        @include media-breakpoint-up(md) {
            .header {
                height: 2.3rem;
            }

            .header-line {
                height: 10px;
            }
        }
    }

    .social-title {
        color: $white;
        font-size: 1.2rem;
        margin-bottom: .6rem;
        font-weight: 600;
    }

    .social-tel {
        color: $white;
        font-size: 1.8rem;
        margin-top: -0.4rem;
    }

    .social {
        a {
            width: 40px;
            height: 40px;
            font-size: 1.3rem;
            border: 3px solid $white;
            text-align: center;
            vertical-align: middle;
            margin-right: 8px;
            color: $white;
            transition: .4s all;

            &:hover {
                background-color: $primary;
            }
        }
    }

}