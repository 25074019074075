@import "../../vendor/bootstrap/functions";
@import "../../vendor/bootstrap/variables";
@import "../../vendor/bootstrap/mixins/breakpoints";

.header-wrapper {
  background-color: $primary;
  transition: background-color .3s;
}

.sticky {
  @include media-breakpoint-up(lg) {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}

.transparent {
  @include media-breakpoint-up(lg) {
    background-color: #00000080;
  }
}

.header {
  border-bottom: 1px solid $white;
  color: $white;
  font-size: 0.85rem;

  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}

.logo {
  width: 100px;

  img {
    width: 90px;
    height: auto;
  }

  @include media-breakpoint-up(md) {
    width: 90px;

    img {
      width: 70px;
      height: auto;
    }
  }

  @include media-breakpoint-up(lg) {
    width: 185px;

    img {
      width: auto;
      height: 80px;
    }
  }
}

.social {
  a {
    width: 26px;
    height: 26px;
    font-size: 1rem;
    border: 1px solid $white;
    text-align: center;
    vertical-align: middle;
    margin-left: 8px;
    color: $white;
    transition: .4s all;

    &:hover {
      background-color: $primary;
    }
  }

  @include media-breakpoint-up(sm) {
    a {
      width: 26px;
      height: 26px;
      font-size: 1rem;
    }
  }

  @include media-breakpoint-up(lg) {
    a {
      width: 30px;
      height: 30px;
      font-size: 1.1rem;
    }
  }
}

.header-menu {
  background: initial;
  justify-content: space-between;
  padding: 0.5rem 0rem;

  :global(.p-menubar-root-list) {
    padding: 0;

    >:global(.p-menuitem) {
      &>a {
        color: $primary !important;

        :global(.p-menuitem-text),
        :global(.p-submenu-icon) {
          color: $primary !important;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 0;

    :global(.p-menubar-root-list) {
      >:global(.p-menuitem) {
        &>a {
          color: $yellow !important;

          :global(.p-menuitem-text),
          :global(.p-submenu-icon) {
            color: $white !important;
          }
        }
      }
    }

    :global(.p-menubar-start) {
      display: none;
    }
  }

  :global(.p-menubar-root-list) {
    >:global(.p-menuitem) {
      :global(.p-menuitem-link):focus {
        box-shadow: none;
      }
    }
  }

  :global(.p-submenu-list) {
    padding: 0;
    font-size: 0.8rem;
    color: $primary;

    :global(.p-menuitem-text) {
      color: $primary;
    }

    :global(.p-menuitem):global(.p-menuitem-active)>:global(.p-menuitem-link) {
      background: $yellow;
    }
  }

  :global(.p-menubar-button) {
    color: $white;
    transition: all .3s;

    &:hover {
      color: $primary;
      background-color: $warning;
    }

    &:focus {
      box-shadow: none;
    }
  }

}